import React, { useState, useEffect } from 'react';
import Markdown from 'react-remarkable';
import { useParams } from 'react-router-dom'
import { FaArrowCircleUp } from 'react-icons/fa';
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import LinearProgress from '@mui/material/LinearProgress'

const highlight = (str, lang) => {
    if (lang && hljs.getLanguage(lang)) {
        try {
            return hljs.highlight(lang, str).value;
        } catch (err) {
            console.error(err);
        }
    }

    try {
        return hljs.highlightAuto(str).value;
    } catch (err) {
        console.error(err);
    }

    return "";
};

const ReportPage = () => {
    const [reportMarkdown, setReportMarkdown] = useState('');
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [fetchingData, setFetchingData] = useState(true)

    const isMobile = window.innerWidth <= 768;
    const { rid } = useParams()

    useEffect(() => {
        const fetchReportMarkdown = async () => {
            try {
                if (!rid) {
                    throw new Error('Markdown ID not provided');
                }
                const response = await fetch(`https://api.materiality.xyz/research/${rid}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch report');
                }
                const markdown = await response.text();
                await setReportMarkdown(markdown);
                setFetchingData(false)
            } catch (error) {
                console.error(error);
            }
        };
        fetchReportMarkdown();
    }, [rid]);

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    if (fetchingData) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '20px' }}>
                <LinearProgress />
            </div>
        )
    } else {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src="logo.png" alt="Logo" style={{ marginBottom: '2%' }} />
                </div>
                <hr style={{ margin: '0px 0', borderColor: '#ccc', borderWidth: '1px', boxShadow: 'none' }} />
                <div style={{
                    maxWidth: isMobile ? '80%' : '40%', margin: '0 auto', padding: '0 20px', textAlign: 'justify'
                }}>
                    <Markdown source={reportMarkdown} options={{ highlight, html: true, linkify: true, xhtmlOut: true }}>{reportMarkdown}</Markdown>
                </div>
                {showScrollButton && (
                    <div
                        onClick={scrollToTop}
                        style={{
                            position: 'fixed',
                            bottom: '20px',
                            right: '20px',
                            cursor: 'pointer',
                            fontSize: '24px',
                            zIndex: 9999,
                        }}
                    >
                        <FaArrowCircleUp />
                    </div>
                )}
            </div>
        );
    }
};

export default ReportPage;
