import './App.css';
import ReportPage from './ReportPagev2';
import AnswerPage from './AnswerPagev2';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/r/:rid' element={<ReportPage />} />
          <Route path='/a/:rid' element={<AnswerPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
