import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { FaArrowCircleUp } from 'react-icons/fa';
import "highlight.js/styles/github.css";
import Button from '@mui/material/Button';
import { Grid } from "@mui/material"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
/*
const highlight = (str, lang) => {
    if (lang && hljs.getLanguage(lang)) {
        try {
            return hljs.highlight(lang, str).value;
        } catch (err) {
            console.error(err);
        }
    }

    try {
        return hljs.highlightAuto(str).value;
    } catch (err) {
        console.error(err);
    }

    return "";
};
*/

const AnswerPage = () => {
    const [reportMarkdown, setReportMarkdown] = useState('');
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [query, setQuery] = useState("")
    const [definitions, setDefinitions] = useState("")
    const [applylaw, setApplyLaw] = useState("")
    const [references, setReferences] = useState([]);
    const [fetchingData, setFetchingData] = useState(true)

    const isMobile = window.innerWidth <= 768;
    const { rid } = useParams()
    useEffect(() => {
        document.title = "Maya Answer";
        const metaDescription = document.querySelector('meta[name="description"]');
        const fetchAnswer = async () => {
            try {
                if (!rid) {
                    throw new Error('Markdown ID not provided');
                }
                const response = await fetch(`https://api.materiality.xyz/arequest/${rid}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch report');
                }
                const answer = await response.json();
                if (metaDescription) {
                    metaDescription.setAttribute('content', answer['answer'].substring(0, 10) + "...");
                }
                setQuery(answer['query'])
                setReportMarkdown(answer['answer'])
                setReferences(answer['refs'])
                setDefinitions(answer['definitions'])
                await setApplyLaw(answer['applylaw'])
                setFetchingData(false)
            } catch (error) {
                console.error(error);
            }
        };
        fetchAnswer();
    }, [rid]);

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    if (fetchingData) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '20px' }}>
                <LinearProgress />
            </div>
        )
    } else {
        return (
            <>
                <Grid container rowSpacing={1} sx={{ marginTop: 0, paddingTop: 0, paddingLeft: '10%', paddingRight: '10%', marginBottom: '10%' }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../mayalogo.png" alt="Logo" style={{ maxHeight: isMobile ? '150px' : '250px', maxWidth: isMobile ? '100px' : '150px' }} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <hr style={{ width: '100%', margin: '1px 1px', borderColor: '#ccc', borderWidth: '1px', boxShadow: 'none' }} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'center' }}></Grid>
                        <Grid item xs={isMobile ? 12 : 8} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {isMobile ? <h3>{query}</h3> : <h1>{query}</h1>}
                        </Grid>
                        <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'center' }}></Grid>
                    </Grid>
                    <>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', marginBottom: isMobile ? '2%' : '1%' }}>
                            <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                            <Grid item xs={isMobile ? 12 : 8}><Divider /></Grid>
                            <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', marginBottom: isMobile ? '2%' : '1%' }}>
                            <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                            <Grid item xs={isMobile ? 12 : 8} sx={{ display: 'flex', justifyContent: 'left' }}>
                                <div style={{ textAlign: 'justify' }} >{reportMarkdown}</div>
                            </Grid>
                            <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                        </Grid>
                    </>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <Grid item xs={isMobile ? 0 : 2}></Grid>
                        <Grid item xs={isMobile ? 12 : 8}><Divider /></Grid>
                        <Grid item xs={isMobile ? 0 : 2}></Grid>
                    </Grid>
                    <>
                        {
                            (definitions !== "" & definitions !== null) ?
                                <>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', marginBottom: isMobile ? '2%' : '1%' }}>
                                        <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                                        <Grid item xs={isMobile ? 12 : 8} sx={{ display: 'flex', justifyContent: 'left', fontWeight: 'bold', fontSize: '1.15rem' }}>{"Definitions"}</Grid>
                                        <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', marginBottom: isMobile ? '2%' : '1%' }}>
                                        <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                                        <Grid item xs={isMobile ? 12 : 8} sx={{ display: 'flex', justifyContent: 'left' }}>
                                            <div style={{ textAlign: 'justify', whiteSpace: 'pre-line' }} >{definitions}</div>
                                        </Grid>
                                        <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                                    </Grid>
                                </>
                                :
                                <></>
                        }
                    </>
                    <>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', marginBottom: isMobile ? '2%' : '1%' }}>
                            <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                            <Grid item xs={isMobile ? 12 : 8} sx={{ display: 'flex', justifyContent: 'left', fontWeight: 'bold', fontSize: '1.15rem' }}>{"Analysis"}</Grid>
                            <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', marginBottom: isMobile ? '2%' : '1%' }}>
                            <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                            <Grid item xs={isMobile ? 12 : 8} sx={{ display: 'flex', justifyContent: 'left' }}>
                                <div style={{ textAlign: 'justify', whiteSpace: 'pre-line' }} >{applylaw}</div>
                            </Grid>
                            <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                        </Grid>
                    </>
                    <>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', marginBottom: isMobile ? '2%' : '1%' }}>
                            <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                            <Grid item xs={isMobile ? 12 : 8} sx={{ display: 'flex', justifyContent: 'left', fontWeight: 'bold', fontSize: '1.15rem' }}>{"References"}</Grid>
                            <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'left' }}></Grid>
                        </Grid>
                    </>
                    {
                        references.map((reference, referenceno) => {
                            return (
                                <Grid key={referenceno} item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'center' }}></Grid>
                                    <Grid item xs={isMobile ? 12 : 8} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header"
                                                    style={{ backgroundColor: '#3b3a30', color: 'white' }}
                                                >
                                                    {"[" + reference["id"] + "] " + reference['title']}
                                                </AccordionSummary>
                                                <AccordionDetails style={{ backgroundColor: '#eaece5', color: 'black', justifyContent: 'center' }}>
                                                    <Grid xs={12}>
                                                        <Grid item style={{ fontStyle: 'italic' }}>
                                                            <span style={{ fontSize: '2em' }}>&ldquo;</span>
                                                            {"... " + reference['text'] + "... "}
                                                            <span style={{ fontSize: '2em' }}>&rdquo;</span>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ marginTop: '1%', marginBottom: '1%' }}>
                                                            <Divider style={{ fontSize: '0.55rem' }}>Why is this relevant?</Divider>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ fontSize: '0.75rem', fontStyle: 'italic' }}>
                                                            {reference['reason']}
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                                <AccordionActions style={{ backgroundColor: '#eaece5', color: 'black' }}>
                                                    <Button target="_blank" href={encodeURI("https://www.google.com/search?q=" + reference['title'])} style={{ background: "#3b3a30", color: 'white' }}>Google</Button>
                                                </AccordionActions>
                                            </Accordion>
                                        </>
                                    </Grid>
                                    <Grid item xs={isMobile ? 0 : 2} sx={{ display: 'flex', justifyContent: 'center' }}></Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                {showScrollButton && (
                    <div
                        onClick={scrollToTop}
                        style={{
                            position: 'fixed',
                            bottom: '20px',
                            right: '20px',
                            cursor: 'pointer',
                            fontSize: '24px',
                            zIndex: 9999,
                        }}
                    >
                        <FaArrowCircleUp />
                    </div>
                )}
            </>
        );
    }
};

export default AnswerPage;
